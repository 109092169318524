<template>
  <div class="register left-float-top">
    <el-row :gutter="20" style="width: 100%">
      <el-col :span="5">
        <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" unique-opened style="padding: 10px 0;" background-color="#187bb2" text-color="#fff" active-text-color="#ffd04b"
                 @select="handleSelect"
                 ref="menu" >
        <el-menu-item index="1">
          <div slot="title" @click="goOrgan">机构信息</div>
        </el-menu-item>
        <el-menu-item index="2">
          <div slot="title" @click="goStation">站内信</div>
        </el-menu-item>
        <el-menu-item index="3">
          <div slot="title" @click="goDataPlatform">登录数据填报平台</div>
        </el-menu-item>
        <el-menu-item index="4">
          <div slot="title" @click="goInitiateauthenticationindex">发起认证</div>
        </el-menu-item>
        <el-menu-item index="5">
          <div slot="title" @click="goConstructionmaterial">建设材料</div>
        </el-menu-item>
        <el-menu-item index="6">
          <div slot="title" @click="goCertificationfootprint">我的认证足迹</div>
        </el-menu-item>
        <el-menu-item index="7">
          <div slot="title" @click="goTreatmentunit">救治单元绑定流程</div>
        </el-menu-item>
      </el-menu>
      </el-col>
      <el-col :span="19"> <div><router-view></router-view></div></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return{
      activeIndex:'',
    }
  },
  mounted() {
    this.activeIndex = localStorage.getItem('activeIndex') || '1';
  },

  methods:{
    handleSelect(key, keyPath) {
      console.log(key)
      this.activeIndex = key;
      localStorage.setItem('activeIndex', key);
    },
    goOrgan:function (){
      this.$router.push('/auth/organ')
    },
    goStation:function (){
      this.$router.push('/auth/station/message')
    },
    goDataPlatform:function (){
      this.$router.push('/auth/data')
    },
    goConstructionmaterial:function(){
      this.$router.push('/auth/material/index')
    },
    goInitiateauthenticationindex:function (){
      this.$router.push('/auth/cert/index')
    },
    goCertificationfootprint:function (){
      this.$router.push('/auth/cert/footprint')
    },
    goTreatmentunit:function (){
      this.$router.push('/auth/treatment')
    }
  }
}
</script>

<style scoped>

</style>